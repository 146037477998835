import React, {Component} from 'react';
import '../css/components/TickAnimation.css';

export default class ComponentTickAnimation extends Component{

  render(){
    return(
      <>
        <div className={`tick-animation`}>
          <div><span><span></span></span></div>
          <div><span><span></span></span></div>
          <div><span><span></span></span></div>
          <div><span></span></div>
          <div></div>
        </div>
      </>
    )
  }

}

export class ComponentTickAnimationHidden extends Component{

  render(){
    return(
      <>
        <div className={`tick-animation tick-animation-hidden`}>
          <div><span><span></span></span></div>
          <div><span><span></span></span></div>
          <div><span><span></span></span></div>
          <div><span></span></div>
          <div></div>
        </div>
      </>
    )
  }

}

import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import HelpComponent from '../Components/Help'
import LocalData from '../js/localData'
import pjson from '../../package.json'
import LoadingSpinner from '../Components/LoadingSpinner'
import axios from 'axios';
import { GetSoftNavbar } from '../js/GetInfo'

export default class ViewPendingApproval extends Component{

    constructor(props){
        super(props)
        this.state = ({
            showHelp: false,
            submitting: false
        })
    }

    cancel = () => {
        this.setState({
            submitting: true
        })
        LocalData.Set("deleted", true)
        window.location.reload()
    }

    render(){
        if(this.state.showHelp) {
            return (
                <HelpComponent
                    done={() => {this.setState({showHelp:false})}}
                    back={() => {this.setState({showHelp:false})}} />
            )
        } else {
            return (
                <>
                    <LoadingSpinner show={this.state.submitting}/>
                    <div className='box-body'>
                        <h1>Approve Yourself</h1>
                        <br/>
                        <p>Normally at this stage of the registration process, an admin of the event would have to approve you to use the app.</p>
                        <br/>
                        <p>As this is only a demo version of the app, you can manually approve youself with the button below.</p>
                    </div>
                    <div className="box-body-button-wrapper">
                        <Button variant="contained" onClick={() => {this.setState({showHelp:true})}}>
                            How to use the app
                        </Button>
                    </div>
                    <div className='box-body-button-wrapper-secondary'>
                        <Button variant="contained" onClick={() => {this.cancel()}}>
                            Back
                        </Button>
                    </div>
                    <div className='box-body-button-wrapper-secondary'>
                        <Button variant="contained" onClick={() => {
                            LocalData.Set("approved", true)
                            LocalData.Set("firstTime", true)
                            window.location.reload()
                        }}>
                            Approve myself to use the app
                        </Button>
                    </div>
                    <p className={GetSoftNavbar() ? "bottom-text-soft" : "bottom-text"}>
                        {`App Version: ${pjson.version}`}
                        <br/>
                        {`User ID: ${LocalData.Get("id")}`}
                    </p>
                </>
            )
        }
    }

}

import React, { Component } from 'react';
import Camera from '../Components/Camera'
import Popup from '../Components/Popup';
import CheckpointStatus from '../Components/CheckpointStatus';
import ComponentTickAnimation, { ComponentTickAnimationHidden } from '../Components/TickAnimation';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { GetSoftNavbar, GetCheckpoints } from '../js/GetInfo';
import LocalData, {QueueController} from '../js/localData'

export default class ViewScan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scanPopup: false,
      scanTitle: '',
      manualPopup: false,
      manualError: false,
      manualInputErrorText: "",
      tickAnimation: <ComponentTickAnimationHidden/>,
      checkpointNotSetPopup: false,
      movedPopup: false,
      lat: null,
      lon: null
    };
  }

  isSweepTeam = () => {
    return (
      LocalData.Get("checkpoint") === "31" ||
      LocalData.Get("checkpoint") === "32" ||
      LocalData.Get("checkpoint") === "33"
    )
  }

  decodeQrCode(rawScanValue){
    
    const scanValue = rawScanValue.split(" ");

    if(scanValue[0] === "Blakfoot"){
      if(scanValue[1] === "Checkpoint"){
        return `C ${scanValue[2]}`;
      } else {
        const number = parseInt(rawScanValue.match(/(\d+)/)[0]);
        if(number > 100 && number < 1000){
          return `T ${number}`
        }
      }
    }

    return null;
  }

  idToName = (value) => {
    var split = value.split(' ') ;
    if(split[0] === "C"){
      try{
        return GetCheckpoints()[split[1]];
      } catch {
        return null
      }
    } else{
      return `Team ${split[1]}`;
    }
  }

  scan = (scanValue) => {

    //If checkpoint not set
    if(LocalData.Get("checkpoint") === null){
      this.setState({
        checkpointNotSetPopup: true
      });
      return 
    }

    this.scanValue = this.decodeQrCode(scanValue);
    
    this.GetLatLong()
  }

  locationOptions = () => ({
    enableHighAccuracy: true,
    timeout: 3000,
    maximumAge: 0
  });

  GetLatLong = async () => {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if(LocalData.Get("lastLat") !== null
            && LocalData.Get("lastLon") !== null
            && (!this.isSweepTeam())){
            if(Math.abs(LocalData.Get("lastLat") - position.coords.latitude) > 0.001
              || Math.abs(LocalData.Get("lastLon") - position.coords.longitude) > 0.002){
              this.setState({
                movedPopup: true,
                lat: position.coords.latitude,
                lon: position.coords.longitude
              })
              return;
            }
          }
          LocalData.Set("lastLat", position.coords.latitude)
          LocalData.Set("lastLon", position.coords.longitude)
          this.submitScan(position.coords.latitude, position.coords.longitude)
        },
        (error) => {
          this.submitScan(null, null)
        },
        this.locationOptions
      );
      return
    }
    this.submitScan(null, null)
  }

  submitScan = (lat,lon) => {

    const scanValueName = this.idToName(this.scanValue);
    if(scanValueName === null || this.state.scanPopup){
      return
    }
    this.setState({
      scanTitle: scanValueName,
      scanPopup: true,
      tickAnimation: <ComponentTickAnimation/>
    });

    const scan = {
      "time":Date.now(),
      "lat":lat,
      "lon":lon,
      "t":this.scanValue,
      "c":LocalData.Get("checkpoint")
    }
    QueueController.Add(scan)
    QueueController.Sync()
  }


  manualTeamNumberChange = (e) => {
    this.setState({
      manualInputValue: e.target.value,
      manualInputErrorText: ""
    })
    if(e.target.value === "" || (e.target.value > 100 && e.target.value < 1000)){
      this.setState({manualError: false});
    } else {
      this.setState({manualError: true});
    }
  }

  render(){
    return (
      <>
        <Popup title={'Uh oh! 😕'} visible={this.state.checkpointNotSetPopup}>
          <p>You can't scan a team until you tell the us which checkpoint you're at in the settings.</p>
          <br/>          
          <div className='box-body-button-wrapper'>
              <Button variant="contained" onClick={() => {
                this.setState({
                  checkpointNotSetPopup:false
                });
                this.props.goToSettings();
              }}>
                  Go to settings
              </Button>
          </div>
        </Popup>

        <Popup title={`No longer ${GetCheckpoints()[localStorage.getItem("checkpoint")]}?`} visible={this.state.movedPopup}>
          <p>It looks like you've moved since your last scan. If you're no longer at {GetCheckpoints()[localStorage.getItem("checkpoint")]}, please update it in the settings and scan this team again.</p>
          <br/>          
          <div className='box-body-button-wrapper'>
              <Button variant="contained" onClick={() => {
                this.setState({
                  movedPopup:false
                });
                this.props.goToSettings();
              }}>
                Go to settings
              </Button>
          </div>
            <div className='box-body-button-wrapper-secondary'>
                <Button variant="contained" onClick={() => {
                  this.setState({movedPopup:false});
                  this.submitScan(this.state.lat, this.state.lon)
                  LocalData.Set("lastLat", this.state.lat)
                  LocalData.Set("lastLon", this.state.lon)
                }}>
                    Scan as {GetCheckpoints()[localStorage.getItem("checkpoint")]} 
                </Button>
            </div>
        </Popup>

        <Popup title={`Scanned ${this.state.scanTitle}`} visible={this.state.scanPopup}>
          {this.state.tickAnimation}
          <div className='box-body-button-wrapper'>
              <Button variant="contained" onClick={() => {
                this.setState({
                  scanPopup:false,
                  tickAnimation: <ComponentTickAnimationHidden/>
                });
              }}>
                  Ok
              </Button>
          </div>
        </Popup>
  
        <Popup title={"Manually Scan"} visible={this.state.manualPopup}>
            <TextField
              ref={this.manualInput}
              className='input'
              label="Team Number"
              variant="outlined"
              inputProps={{inputMode: "numeric"}}
              onChange={this.manualTeamNumberChange}
              error={this.state.manualError?true:false}
              helperText={this.state.manualInputErrorText}
              />
            <div className='box-body-button-wrapper'>
                <Button variant="contained" onClick={() => {
                  if(!this.state.manualError){
                    this.setState({
                      manualPopup:false
                    });
                    if(this.state.manualInputValue !== undefined
                      && this.state.manualInputValue !== ""){
                      this.scan(`Blakfoot AGE A ${this.state.manualInputValue}`)
                    }
                  } else {
                    this.setState({manualInputErrorText: "Invalid Number"})
                  }
                  }}>
                    Scan
                </Button>
            </div>
            <div className='box-body-button-wrapper-secondary'>
                <Button variant="contained" onClick={() => {
                  this.setState({manualPopup:false});
                  }}>
                    Cancel
                </Button>
            </div>
        </Popup>
        
        <CheckpointStatus hide={true} open={true} />

        <div className={`box-body-button-wrapper-secondary ${GetSoftNavbar()?'box-above-nav-soft':'box-above-nav'}`}>
          <Button variant="contained" onClick={() => {
            this.setState({
              manualPopup:true,
            });
          }}>
            Manually Enter A Team
          </Button>
        </div>
        
        <Camera onScan={(scanValue) => { this.scan(scanValue) }}/>
  
      </>
    );
  }

}
import React from 'react'
import '../css/components/CheckpointStatus.css'

export default function ComponentCheckpointStatus(props){

    if(props.hide === true){
        return(<></>)
    }

    return(
      <div className={`box-body ${props.open?"checkpoint-status-open":"checkpoint-status-closed"}`}>
        <p>This checkpoint is currently <strong>{props.open?"open":"closed"}</strong></p>
      </div>
    )
  
  }
const timeOptions = {
  hour: 'numeric', minute: 'numeric',
  hour12: false
}
const dateOptions = {
  day: 'numeric', month: 'numeric', year: 'numeric',
  hour12: false
}

export const ConvertMilisecondsToDisplayTime = (time) => {
  return new Intl.DateTimeFormat('default', timeOptions).format(time)
}

export const ConvertMilisecondsToDisplayDateTime = (time) => {
  try{
    return `${ConvertMilisecondsToDisplayTime(time)} (${new Intl.DateTimeFormat('default', dateOptions).format(time)})`
  } catch(e) {
    return ""
  } 
}
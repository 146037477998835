import React from 'react';
import QrCode from '../Components/QrCodeGenerator';

export default function ViewShowQR() {

  return (
    <>
      <div className='box-body'>
        <QrCode value={`Blakfoot Checkpoint ${localStorage.getItem("checkpoint")}`}/>
      </div>
      <div className='box-body'>
          <p>Sweep teams must get this QR code scanned by every checkpoint they pass through.</p>
      </div>
    </>
  );

}
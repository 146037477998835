import React from 'react';
import { GetSoftNavbar } from '../js/GetInfo'

export default function ViewIos(props) {

  //If not installed
  if(true){
    return (
      <>
        <div className='box-head'>
          <h1>Blakfoot Setup</h1>
        </div>
        <div className='box-body'>
           <p>Tap <img style={{height:'1em'}} src='ShareIcon.png' alt='iOS Share Icon'/> at the bottom of the screen</p>
        </div>
        <div className='box-body'>
            <p>Then choose 'Add to Home Screen'</p>
        </div>
        <div className='box-body'>
            <p>Once you've added the Blakfoot app, you'll be able to find it installed on your phone</p>
        </div>
        <p className={GetSoftNavbar() ? "bottom-text-soft" : "bottom-text"}>{props.version}</p>
      </>
    );
  }

}
    
import React from 'react'
import '../css/components/LoadingSpinner.css'
import Logo from '../img/logo192.png'

export default function Loading(props){

    return(

        <div id="loading-spinner" className={props.show?"":"transparent"}>
            <div>
                <img src={Logo} alt="Loading"/>
            </div>
        </div>

    )

}
import React from 'react';
import TeamsTable from '../Components/TeamsTable'
import LocalData from '../js/localData';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ConvertMilisecondsToDisplayDateTime } from '../js/helper'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ViewCheckpoint() {

  const uptodate = new URL(window.location.href).searchParams.get("uptodate")
  const [showSnackbar, setShowSnackbar] = React.useState(uptodate !== "null");

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setShowSnackbar(false);
    }
  };

  return (
    <>
      <Snackbar open={showSnackbar} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" style={{ backgroundColor: "#333" }}>
          Unable to get the lastest data. {uptodate !== "0" ? `These times are as of ${ConvertMilisecondsToDisplayDateTime(parseInt(uptodate))}` : ""}
        </Alert>
      </Snackbar>
      <TeamsTable checkpoint={LocalData.Get("searchCheckpoint")} />
    </>
  );

}
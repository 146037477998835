import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import Popup from '../Components/Popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import pjson from '../../package.json'
import * as GetInfo from '../js/GetInfo'
import LocalData, {QueueController} from '../js/localData'
import axios from 'axios';
import LoadingSpinner from '../Components/LoadingSpinner'

export default class ViewAdvancedSettings extends Component{

  constructor(props) {
    super(props);
    this.state = {
      clearStoragePopup: false,
      latLong: "",
      testOutput: "",
      loading: false
    };
  }

  clearLocalStorage = () => {
    this.setState({loading: true})
    axios
      .post(`${pjson.apiUrl}/user/delete`,{"id":LocalData.Get("id")})
      .then(res => {
        localStorage.clear();
        window.location.reload()
      })
      .catch(error => {
        localStorage.clear();
        window.location.reload()
      });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }
  
  render() {
    return (
      <>
        <LoadingSpinner show={this.state.loading}/>

        <div className='box-body'>
          <h1>Pending Scans</h1>
          {
            QueueController.Get().length > 0
            ?
            QueueController.Get().map((scan) => 
              <p>{new Date(scan.time).toLocaleTimeString()} &nbsp; {scan.t} &nbsp; C {scan.c}</p>
            )
            :
            <p>All your scans have been sent to the server.</p>
          }
        </div>
        <div className={QueueController.Get().length > 0 ? "box-body-button-wrapper" : "box-body-button-wrapper-secondary"}>
          <Button variant="contained" onClick={async () => {
            this.setStateAsync({loading: true})
            await QueueController.Sync();
            this.setStateAsync({loading:false})
          }}>
            Force sync
          </Button>
        </div>

        <div className='box-body'>
          <h1>Device & App Infomation</h1>
          <p>ID: {LocalData.Get("id")}</p>
          <p>Platform: {GetInfo.GetPlatform()}</p>
          <p>Browser: {GetInfo.GetBrowser()}</p>
          <p>API: {pjson.apiUrl}</p>	
          <p>Installed: {GetInfo.GetInstalled() ? <FontAwesomeIcon icon={faCheckCircle}/>:<FontAwesomeIcon icon={faTimesCircle}/>}</p>
          <p>Soft navigation: {GetInfo.GetSoftNavbar() ? <FontAwesomeIcon icon={faCheckCircle}/>:<FontAwesomeIcon icon={faTimesCircle}/>}</p>
          <p>Release: {GetInfo.IsRelease() ? <FontAwesomeIcon icon={faCheckCircle}/>:<FontAwesomeIcon icon={faTimesCircle}/>}</p>
          <p>Location Available: {navigator.geolocation ? <FontAwesomeIcon icon={faCheckCircle}/>:<FontAwesomeIcon icon={faTimesCircle}/>}</p>
          <p>App Version: {pjson.version}</p>
          <p>React Version: {pjson.dependencies.react.substr(1)}</p>
        </div>
        <div className='box-body-button-wrapper'>
          <Button variant="contained" onClick={() => {
            this.setState({
              clearStoragePopup:true,
            });
          }}>
            Clear All Local Data
          </Button>
        </div>
        
        <Popup title={"Warning ⚠️"} visible={this.state.clearStoragePopup}>
            <p>This remove your access to the app, and you will need to re-register & be approved to use it again. Are you sure you want to do this?</p>
            <br/>
            <div className='box-body-button-wrapper'>
                <Button variant="contained" onClick={this.clearLocalStorage}>
                    Reset App
                </Button>
            </div>
            <div className='box-body-button-wrapper-secondary'>
                <Button variant="contained" onClick={() => {
                  this.setState({clearStoragePopup:false});
                  }}>
                    Cancel
                </Button>
            </div>
        </Popup>
      </>
    );
  }

}
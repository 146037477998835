import React, { Component } from 'react';
import './css/App.css';
import * as GetInfo from './js/GetInfo.js';
import ComponentNavbar from './Components/Navbar';
import ViewMain from './Views/Main';
import ViewIos from './Views/iOS';
import ViewAndroid from './Views/Android';
import ViewDesktop from './Views/Desktop';
import ViewShowQr from './Views/ShowQR';
import ViewCheckpoint from './Views/Checkpoint';
import ViewCheckpointSearch from './Views/Checkpoint Search';
import ViewAdvancedSettings from './Views/AdvancedSettings';
import ViewHelp from './Views/Help';
import ViewRegister from './Views/Register';
import ViewPendingApproval from './Views/PendingApproval';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import pjson from '../package.json';
import LocalData, {QueueController, CheckpointController} from './js/localData';
import ViewFirstLoad from './Views/FirstLoad';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7413DC',
    }
  }
});

class AppFrame extends Component{
  render() {
    return (
      <div className='scrollable'>
        <ThemeProvider theme={theme}>
          {this.props.children}
        </ThemeProvider>
      </div>
    )
  }
}

export default class App extends Component {

  constructor(props){
    super(props)
    this.syncScans()
    this.getLatestCheckpointData()
  }

  syncScans = () => {
    QueueController.Sync() 
    setTimeout(this.syncScans, 1000 * 60)
  }

  getLatestCheckpointData = () => {
    CheckpointController.Sync(LocalData.Get("checkpoint"))
    setTimeout(this.syncScans, 1000 * 60 * 60)
  }

  render(){

    const platform = GetInfo.GetPlatform();
    const version = GetInfo.IsRelease() ? pjson.version : `${pjson.version} DEVELOPMENT VERSION`;

    //If installed
    if(GetInfo.GetInstalled(GetInfo.IsRelease())){
      //If approved to use the app
      if(LocalData.Get("approved") === "true"){
        if(LocalData.Get("firstTime") === "true"){
          return(
            <AppFrame>
              <ViewFirstLoad/>
            </AppFrame>
          )
        }
        return(
          <AppFrame>
            <Router> 

              <ComponentNavbar/>

              <Route path="/">
                <Redirect to="/main"/>
              </Route>

              <Route exact path="/main">
                <ViewMain updateNavbar={() => {this.forceUpdate()}} />
              </Route>
              <Route exact path="/showqr">
                <ViewShowQr />
              </Route>
              <Route exact path="/checkpoint">
                <ViewCheckpoint />
              </Route>
              <Route exact path="/checkpointSearch">
                <ViewCheckpointSearch />
              </Route>
              <Route exact path="/advancedsettings">
                <ViewAdvancedSettings />
              </Route>
              <Route exact path="/help">
                <ViewHelp />
              </Route>

            </Router>
          </AppFrame>
        );
      } else if (LocalData.Get("id") !== null
        && LocalData.Get("id") !== "null"
        && LocalData.Get("deleted") !== true
        && LocalData.Get("deleted") !== "true"){
        return (
          <AppFrame>
            <ViewPendingApproval/>
          </AppFrame>
        )
      } else {
        return (
          <AppFrame>
            <ViewRegister />
          </AppFrame>
        )
      }
      
    }

    if(platform === 'iOS'){
      return (
        <div className="App">
          <ViewIos version={version}/>
        </div>
      );
    } else if (platform === 'Android') {
      return (
        <div className="App">
          <ViewAndroid version={version} browser={GetInfo.GetBrowser()}/>
        </div>
      );
    } else {
      return (
        <div className="App">
          <ViewDesktop version={version}/>
        </div>
      );
    }
  }
}

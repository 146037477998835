import React, {Component} from 'react';
import Rive from 'rive-canvas';
const pjson = require('../../package.json');

export default class AnimationComponent extends Component{

    componentDidMount = () => {

        const riveVersion = pjson.dependencies["rive-canvas"].replace('^','')

        Rive({
            locateFile: (file) => `https://unpkg.com/rive-canvas@${riveVersion}/${file}`
        }).then((rive) => {
            //Get animation file
            const req = new Request(this.props.file);
            fetch(req).then((res) => {
                console.log(res)
                return res.arrayBuffer();
            }).then((buf) => {
                const file = rive.load(new Uint8Array(buf));
                const artboard = file.defaultArtboard();
                

                //Create object containing all the animation instances of the seqence
                var animations = {};
                for (var i = 0; i < this.props.animationNumberOfPages; i++) {
                    animations[`${i}A`] = artboard.animation(`${i}A`)
                    animations[`${i}B`] = artboard.animation(`${i}B`)
                    animations[`${i}C`] = artboard.animation(`${i}C`)
                }

                const canvas = document.getElementById('riveCanvas');
                const ctx = canvas.getContext('2d');

                const renderer = new rive.CanvasRenderer(ctx);
                artboard.advance(0);

                ctx.save();
                renderer.align(rive.Fit.contain, rive.Alignment.center, {
                    minX: 0,
                    minY: 0,
                    maxX: canvas.width,
                    maxY: canvas.height
                }, artboard.bounds);
                artboard.draw(renderer);
                ctx.restore();

                let lastTime = 0;

                var lastAnimationName = null;
                var instances = [];

                var currentAnimationProgress = 0;
                var animationStarted = false;

                function draw(time) {
                    if (!lastTime) {
                        lastTime = time;
                    }
                    const elapsedTime = (time - lastTime) / 1000;
                    currentAnimationProgress += elapsedTime;
                    lastTime = time;

                    currentAnimationProgress += (time - lastTime);

                    if(document.getElementById('animationName') === null)
                        return;
                    const currentAnimationName = document.getElementById('animationName').value;
                    
                    //When page changes
                    if(lastAnimationName !== currentAnimationName && currentAnimationProgress >= 0){
                        //Clear all animations except exits ones
                        instances = instances.filter((value) => {
                            return value["type"] === "C"
                        })
                        //Reset animation progress
                        currentAnimationProgress = 0;
                        animationStarted = false;
                        //Play end animation (if not the first time the animations loaded)
                        if(lastAnimationName !== null){
                            instances.push({
                                "type": "C",
                                "instance": new rive.LinearAnimationInstance(animations[`${lastAnimationName}C`]),
                                "time": 0.5
                            })
                        }
                        lastAnimationName = currentAnimationName;
                    }
                    //0.5 secs after the end animation, start the start animation
                    if(!animationStarted && currentAnimationProgress > 0.5){
                        animationStarted = true;
                        //Start new animation
                        instances.push({
                            "type": "A",
                            "instance": new rive.LinearAnimationInstance(animations[`${lastAnimationName}A`]),
                            "time": 2
                        })
                        instances.push({
                            "type": "B",
                            "instance": new rive.LinearAnimationInstance(animations[`${lastAnimationName}B`]),
                            "time": 0
                        })
                    }


                    var instancesToBeRemoved = [];
                    //Advance each instance
                    instances.forEach(instance => {

                        //If animation finished and isn't a looping animation, kill it
                        if(instance["time"] < 0 && instance["type"] !== "B"){
                            instancesToBeRemoved.push(instance);
                        }

                        //Subtract frame duration from remaining time
                        instance["time"] = instance["time"] - elapsedTime;

                        //Advance the animation - if it's a looping animation, only advance it if it's been running for over a second
                        if(instance["type"] !== "B" || instance["time"] < -1){
                            instance["instance"].advance(elapsedTime);
                            instance["instance"].apply(artboard, 1.0);
                        }
                    })
                    instances = instances.filter((value) => {
                        return !instancesToBeRemoved.includes(value)
                    })


                    artboard.advance(elapsedTime);
        
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.save();
                    renderer.align(rive.Fit.contain, rive.Alignment.center, {
                        minX: 0,
                        minY: 0,
                        maxX: canvas.width,
                        maxY: canvas.height
                    }, artboard.bounds);
                    artboard.draw(renderer);
                    ctx.restore();
        
                    requestAnimationFrame(draw);
                }
                requestAnimationFrame(draw);

            });
        })
    }


    render = () => {
        return(
            <>
                <input id="animationName" value={this.props.animationIndex} style={{display: "none"}}/>
                <canvas id="riveCanvas"
                    width={this.props.width * window.devicePixelRatio}
                    height={this.props.height * window.devicePixelRatio}
                    style={{width:'100%', height:'100%'}} />
            </>
        )
    }
    
}
import axios from 'axios';
import pjson from '../../package.json'

export default class LocalData {
    static Get = (key) => {
        var value = localStorage.getItem(key);
        return value === "" ? null : value;
    }
    static Set = (key,value) => {
        localStorage.setItem(key,value)
    }
    
}

export class QueueController {
    static Get = () => {
        var queue = JSON.parse(localStorage.getItem("scanQueue"))
        return queue === null ? [] : queue
    }
    static Add = (scan) => {
        var queue = this.Get()
        queue.push(scan)
        localStorage.setItem("scanQueue",JSON.stringify(queue))

        // If team, update the relevent checkpoint locally
        if(scan.t.charAt(0) === "T"){
            const teamNumber = scan.t.substring(2,5)
            var checkpoint = CheckpointController.Get(scan.c);
            if(checkpoint === undefined){
                checkpoint = {}
            }
            checkpoint[teamNumber] = {
                "t":scan.time,
                "c":1 //Colour
            }
            CheckpointController.Set(scan.c, checkpoint)
        }
    }
    static AddUserDetails = () => {
        localStorage.setItem("syncUserDetails",true)
    }
    static Sync = async () => {
        if(!(this.Get().length > 0)){
            return false
        }
        const body = {
            "id":LocalData.Get("id"),
            "scans":this.Get()
        }
        localStorage.setItem("scanQueue",null)

        if(localStorage.getItem("syncUserDetails")){
            axios.put(`${pjson.apiUrl}/user`,{
                "id":LocalData.Get("id"),
                "Name":LocalData.Get("name"),
                "Phone":LocalData.Get("phone")
            })
            .then(res => {
                localStorage.setItem("syncUserDetails",null)
            })
        }
        return true
    }
}

export class CheckpointController {
    static GetAll = () => {
        const checkpoints = JSON.parse(localStorage.getItem("checkpoints"))
        if(checkpoints === null){
            return {}
        }
        return checkpoints
    }
    static Get = (checkpointId) => {
        var checkpoints = this.GetAll()
        try{
            return checkpoints[checkpointId]
        } catch(e) {
            return {}
        }
    }
    static Set = (checkpointId, checkpoint) => {
        var checkpoints = this.GetAll()
        checkpoint["LastSync"] = checkpoint["LastSync"] ?? 0
        checkpoints[checkpointId] = checkpoint
        localStorage.setItem("checkpoints",JSON.stringify(checkpoints))
    }
    // Return undefined if data has just been fetched, or return the time it was last up to date
    static Sync = async (checkpointId) => {

        // Get the time this checkpoint was last synced
        var lastSync = 0
        try{
            lastSync = CheckpointController.Get(checkpointId).LastSync
        } catch(e) {}

        // If there are pending scans in the queue, send them first
        if(QueueController.Get().length > 0){
            const syncedQueue = await QueueController.Sync()
            // If failed to sync queue, return the last sync
            if(!syncedQueue){
                return lastSync
            }
        }
        
        // By this point the queue is up-to-date
        const body = {
            "id":LocalData.Get("id"),
            "checkpoint":checkpointId
        }
        try {
            const response = await axios.post(`${pjson.apiUrl}/scan/getCheckpoint`,body)
            var newCheckpoint = {}
            for (const [key, value] of Object.entries(response.data)) {
                newCheckpoint[key.replace("T ","")] = value
            }
            newCheckpoint["LastSync"] = new Date().getTime()
            this.Set(checkpointId, newCheckpoint)
            return null
        } catch(err) {
            return lastSync
        }

    }
}
import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import '../css/components/Camera.css';

export default class ComponentCamera extends Component {
  constructor(props){
    super(props);
    this.handleScan = this.handleScan.bind(this)
  }
  handleScan(data){
    if(data != null){
      this.props.onScan(data);
    }
  }
  render(){
    const previewStyle = {
    } 
    return(
      <div id="camera">
        <QrReader
          delay={250}
          style={previewStyle}
          facingMode={"environment"}
          onScan={this.handleScan}
          onError={console.log}
          />
        <p>Loading Camera</p>
      </div>
    )
  }
}
import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import HelpComponent from '../Components/Help'; 
import LocalData from '../js/localData'

export default class ViewFirstLoad extends Component{

    constructor(props){
        super(props)
        this.state = ({
            showHelp: false
        })
    }

    render(){
        if(this.state.showHelp) {
            return (
                <HelpComponent
                    done={() => {
                        LocalData.Set("firstTime", false);
                        window.location.reload();
                    }}
                    back={() => {this.setState({showHelp:false})}} />
            )
        } else {
            return (
                <>
                    <div className='box-body'>
                        <h1>Welcome!</h1>
                        <br/>
                        <p>You've now been approved to use the app.</p>
                        <br/>
                        <p>Please make sure you leave your mobile data on during the hike, so the app updates control when you have a signal.</p>
                        <br/>
                        <p>Just before you start, please read through the instructions by clicking the button below.</p>
                    </div>
                    <div className="box-body-button-wrapper">
                        <Button variant="contained" onClick={() => {this.setState({showHelp:true})}}>
                            Get started
                        </Button>
                    </div>
                </>
            )
        }
    }

}

import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as GetInfo from '../js/GetInfo';
import LocalData, { CheckpointController, QueueController } from '../js/localData';

export default class ViewSettings extends Component {

  render(){

    const checkpoints = GetInfo.GetCheckpoints();
    var menuItems = [<MenuItem value={""}><em>None</em></MenuItem>];
    for (const [key, value] of Object.entries(checkpoints)) {
      menuItems.push(<MenuItem value={key}>{value}</MenuItem>);
    }

    return (
      <>
        <div className='box-body'>
          <p>Please fill out your details below, and update your current checkpoint when you move checkpoint.</p>
        </div>
        <div className='box-body'>
          <TextField className='input'
            label="Name"
            variant="outlined"
            inputProps={{inputMode: "text"}}
            defaultValue={LocalData.Get("name")}
            onChange={(event)=>{
              LocalData.Set("name", event.target.value)
              QueueController.AddUserDetails()
            }} />
        </div>
        <div className='box-body'>
          <TextField className='input'
            label="Mobile Number"
            variant="outlined"
            inputProps={{inputMode: "tel"}}
            defaultValue={LocalData.Get("phone")}
            onChange={(event)=>{
              LocalData.Set("phone", event.target.value)
              QueueController.AddUserDetails()
            }} />
        </div>
        <div className='box-body' ref={this.checkpointComponent}>
          <FormControl variant="outlined" className={'input'}>
            <InputLabel id="demo-simple-select-outlined-label">Current Checkpoint</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={LocalData.Get("checkpoint")}
              onChange={(event) => {
                LocalData.Set("checkpoint", event.target.value)
                LocalData.Set("lastLat", null)
                LocalData.Set("lastLon", null)
                this.props.updateNavbar();
                CheckpointController.Sync(event.target.value)
              }}
              label="Current Checkpoint" >
              {menuItems}
            </Select>
          </FormControl>
        </div>
      </>
    );
  }
}
import React, {Component} from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LocalData from '../js/localData'
import axios from 'axios';
import pjson from '../../package.json';
import LoadingSpinner from '../Components/LoadingSpinner'

export default class ViewRegister extends Component{

    constructor(props){
        super(props)
        this.state = {
            nameError: false,
            phoneError: false,
            submitting: false
        }
        LocalData.Set("approved", false)
    }


    submit = () => {
        if(this.state.submitting){
            return;
        }
        this.setState({
            nameError: LocalData.Get("name") === null,
            phoneError: LocalData.Get("phone") === null,
        })
        if(LocalData.Get("name") !== null && LocalData.Get("phone") !== null){
            this.setState({
                submitting: true
            })
            this.createUser()
        }
    }

    createUser = () => {
        console.log("Creating User")
        var user = {
            "Name":LocalData.Get("name"),
            "Phone":LocalData.Get("phone")
        }
        console.log(pjson.apiUrl)

        console.log(LocalData.Get("deleted"))
        console.log(LocalData.Get("id"))

        // If user has already been registered, but deleted
        if(LocalData.Get("deleted") && LocalData.Get("id") !== null){
            user.id = LocalData.Get("id");
            LocalData.Set("deleted",false)
            window.location.reload()
        } else {
            LocalData.Set("id", "demo-id")
            window.location.reload()
        }
    }

    render() {
        return(
            <>
                <LoadingSpinner show={this.state.submitting}/>
                <div className='box-body'>
                    <h1>Thank you for installing the Blakfoot App!</h1>
                    <br/>
                    <p>Before you can get using the app, please fill out your details below to verify who you are.</p>
                    <br/>
                    <p>The app will also request permission to access your camera & location. These will be used to scan team/checkpoint QR codes and identify where a scan takes place.</p>
                    <br/>
                    <p>We do not share any data with 3rd parties.</p>
                </div>
                <div className='box-body'>
                    <TextField className='input'
                        label="Full Name"
                        variant="outlined"
                        inputProps={{inputMode: "text"}}
                        error={this.state.nameError ? true : false}
                        defaultValue={LocalData.Get("name")}
                        onChange={(event)=>{LocalData.Set("name", event.target.value)}} />
                </div>
                <div className='box-body'>
                    <TextField className='input'
                        label="Mobile Number"
                        variant="outlined"
                        inputProps={{inputMode: "tel"}}
                        error={this.state.phoneError ? true : false}
                        defaultValue={LocalData.Get("phone")}
                        onChange={(event)=>{LocalData.Set("phone", event.target.value)}} />
                </div>
                <div className={`box-body-button-wrapper${this.state.submitting?"-secondary":""}`}>
                    <Button variant="contained" onClick={this.submit}>
                        Continue
                    </Button>
                </div>
            </>
        )
    }

}



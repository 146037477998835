import React from 'react';
import { useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {GetCheckpoints} from '../js/GetInfo';
import LocalData, { CheckpointController } from '../js/localData';
import LoadingSpinner from '../Components/LoadingSpinner'

export default function ViewCheckpointSearch() {

  const [checkpoint, setCheckpoint] = React.useState('');
  const [searching, setSearching] = React.useState(false);

  const history = useHistory();

  const changeCheckpoint = (event) => {
    LocalData.Set("searchCheckpoint",event.target.value)
    setCheckpoint(event.target.value);
  };

  const checkpoints = GetCheckpoints();
  var menuItems = [];
  for (const [key, value] of Object.entries(checkpoints)) {
    menuItems.push(<MenuItem value={key}>{value}</MenuItem>);
  }

  const searchCheckpoint = () => {
    if(checkpoint !== "") {
      setSearching(true);
      CheckpointController.Sync(checkpoint).then(response => {
        history.push(`/checkpoint?uptodate=${response}`);
      })
    }
  }

  return (
    <>
      <LoadingSpinner show={searching}/>
      <div className='box-body'>
          <p>Please select a checkpoint below to view which teams have passed through it.</p>
      </div>
      <div className='box-body'>
        <FormControl variant="outlined" className={'input'}>
          <InputLabel id="demo-simple-select-outlined-label">Select a Checkpoint</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={checkpoint}
            onChange={changeCheckpoint}
            label="Current Checkpoint"
          >
            {menuItems}
          </Select>
        </FormControl>
      </div>
      <div className={`box-body-button-wrapper${checkpoint === ""?"-secondary":""}`}>
        <Button variant="contained" color="primary" onClick={searchCheckpoint}>
          Search
        </Button>
      </div>
    </>
  );

}
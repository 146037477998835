import React, { Component } from 'react';
import { GetSoftNavbar } from '../js/GetInfo';
import '../css/components/Navbar.css';
import {
  BottomNavigationAction,
  BottomNavigation
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  CameraAlt as ScanIcon,
  PlaylistAddCheck as ScannedIcon
} from '@material-ui/icons';

import ViewSettings from './Settings';
import ViewScan from './Scan';
import ViewScanned from './Scanned';

export default class ViewMain extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      viewName: "Scan"
    };
  }

  view = () => {
    switch(this.state.viewName){
      case "Settings":
        return(
          <ViewSettings updateNavbar={this.props.updateNavbar}  />
        )
      case "Scan":
        return(
          <ViewScan goToSettings={this.goToSettings} />
        )
      case "Scanned":
        return(
          <ViewScanned />
        )
      default:
        return(<></>)
    }
  }

  goToSettings = () => this.setState({viewName: 'Settings'});

  render(){
    return (
      <>
        <div className={`${GetSoftNavbar()?'view-wrapper-soft':'view-wrapper'}`}>
          {this.view()}
        </div>
        <BottomNavigation
          value={this.state.viewName}
          onChange={(event, newValue) => {
            this.setState({viewName:newValue});
          }}
          showLabels
          className={`navbar ${GetSoftNavbar()?'navbar-soft':''}`} >
          <BottomNavigationAction
            label="Settings"
            icon={
              <SettingsIcon />
            }
            value="Settings"/>
          <BottomNavigationAction
            label="Scan" // Was "Scan a Team"
            icon={
              <ScanIcon />
            }
            value="Scan" />
          <BottomNavigationAction
            label="Scanned" // was "View Scanned"
            icon={
              <ScannedIcon />
            }
            value="Scanned" />
        </BottomNavigation>
      </>
    )
  }

}
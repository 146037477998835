import React from 'react';
import {
    Link,
    useLocation
} from 'react-router-dom';
import '../css/components/Header.css';
import {
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    ArrowBack as BackIcon,
    CropFree as QrIcon,
    LocationOn as LocationIcon,
    Settings as SettingsIcon,
    Help as HelpIcon
} from '@material-ui/icons'
import * as GetInfo from '../js/GetInfo'
import LocalData from '../js/localData'
import pjson from '../../package.json'

export default function ComponentNavbar(props) {

    const formatCheckpointTitle = (localDataCheckpoint) => {
        if (LocalData.Get(localDataCheckpoint) !== null) {
            title = GetInfo.GetCheckpoints()[localStorage.getItem(localDataCheckpoint)];
            if (title.length > 25) {
                const titleWords = title.split(" ")
                if (titleWords.length > 1) {
                    title = titleWords[0] + " " + titleWords[1]
                } else {
                    title = titleWords[0]
                }
            }
        }
    }

    const location = useLocation();
    var title = "Blakfoot";
    switch (location.pathname) {
        case "/main":
            formatCheckpointTitle("checkpoint");
            break;
        case "/showqr":
            title = "Sweep QR Code";
            break;
        case "/checkpointSearch":
            title = "Lookup Checkpoint";
            break;
        case "/checkpoint":
            title = "Lookup Checkpoint";
            formatCheckpointTitle("searchCheckpoint");
            break;
        case "/advancedsettings":
            title = "Advanced Settings";
            break;
        case "/help":
            title = "Help";
            break;
        default:
            break;
    }

    const [state, setState] = React.useState({
        open: false,
    });

    const toggleDrawer = (state) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ open: state });
    };

    const listItemQR = () => {
        let currentCheckpoint = LocalData.Get("checkpoint")
        if(currentCheckpoint === "31" || currentCheckpoint === "32" || currentCheckpoint === "33"){
            return (
                <ListItem button component={Link} to="/showqr">
                    <ListItemIcon>
                        <QrIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Sweep QR Code'} />
                </ListItem>
            )
        }
        return (
            <></>
        );
    }
    const listItemCheckpoint = () => (
        <ListItem button component={Link} to="/checkpointSearch">
            <ListItemIcon>
                <LocationIcon />
            </ListItemIcon>
            <ListItemText primary={'Lookup Checkpoint'} />
        </ListItem>
    )
    const listItemAdvancedSettings = () => (
        <ListItem button component={Link} to="/advancedsettings">
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Advanced Settings'} />
        </ListItem>
    )
    const listItemHelp = () => (
        <ListItem button component={Link} to="/help">
            <ListItemIcon>
                <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={'Help'} />
        </ListItem>
    )

    const list = () => (
        <div className={'list-menu'}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}>
            <List>
                {listItemQR()}
                {listItemCheckpoint()}
                {listItemAdvancedSettings()}
                {listItemHelp()}
            </List>
            <Divider />
            <List>
                <ListItem>
                    <ListItemText primary="Made by Max Robinson on behalf of Warrington Scouts" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem>
                    <ListItemText primary={GetInfo.IsRelease() ? `Version ${pjson.version}` : `${pjson.version} DEVELOPMENT VERSION`} />
                </ListItem>
            </List>
        </div>
    );

    const navigateBack = () => {
        window.history.back();
    }

    var menu = <></>;
    var back = <></>;
    if (location.pathname === '/main') {
        menu =
            <IconButton style={{ color: 'white' }} aria-label="Menu" component="span" onClick={toggleDrawer(true)}>
                <MenuIcon />
            </IconButton>
    } else {
        back =
            <IconButton style={{ color: 'white' }} onClick={navigateBack}>
                <BackIcon />
            </IconButton>
    }


    return (
        <>
            <div className='header'>
                <Drawer anchor={'right'} open={state['open']} onClose={toggleDrawer()}>
                    {list()}
                </Drawer>
                {menu}
                <h1>{title}</h1>
                {back}
            </div>
        </>
    );

}
import React from 'react';
import TeamsTable from '../Components/TeamsTable';
import LocalData from '../js/localData'

export default function ViewScanned() {

  if(LocalData.Get("checkpoint") === null){
    return(
      <>
        <div className='box-body'>
          <h1>Please select a checkpoint</h1>
          <p>To use this app you need to select the checkpoint you're at in the settings page.</p>
        </div>
      </>
    )
  } else {
    return (
      <>
        <TeamsTable checkpoint={LocalData.Get("checkpoint")}/>
      </>
    );
  }

}
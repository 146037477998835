import React from 'react';
import '../css/components/Popup.css';

export default function ComponentPopup(props){

  return(
    <>
      <div className={`popup ${props.visible?"visible":"hidden"}`}>
        <div>
          <div className={"box-body"}>
            <h1>{props.title}</h1>
            <div className='box-divider'></div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  )

}


//Get device browser
export const GetPlatform = () => {

    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
        return 'iOS';
    //} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    } else if (navigator.userAgent.match(/Android/i)) {
        return 'Android';
    } else {
        return 'Unknown';
    }
}

export const GetBrowser = () => {

    const platform = GetPlatform();

    if(platform === 'iOS'){
        return "Safari";
    } else if (platform === 'Android'){
        if(navigator.userAgent.match(/SamsungBrowser/i)){
            return 'Samsung';
        } else if(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)){
            return 'Chrome';
        }
    }
    return 'Unknown';

}
  
//Get if added to home screen / installed
export const GetInstalled = (release) => {

    if(!release){
        return true;
    }

    const platform = GetPlatform();
    const browser = GetBrowser();
    if (platform === 'iOS') {
        if (window.navigator.standalone === true) {
            return true;
        }
    } else if (platform === 'Android') {
        if(browser === 'Chrome'){
            if (window.matchMedia('(display-mode: standalone)').matches) {
                return true;
            }
        } else if (browser === 'Samsung'){
            const heightDiff = window.innerHeight / window.screen.height;
            //Greater than 0.9 means no navigation bar at the top
            //Less than 0.7 means keyboard is open, so it should be assuming that it's installed
            if(heightDiff > 0.9 || heightDiff < 0.7){
                return true;
            }
        }
    }
    return false;
} 

//Get if soft navbar (overlaying screen) is being used
export const GetSoftNavbar = () => {

    const sHeight = window.screen.height;
    const wHeight = window.innerHeight;

    // Height Values when installed
    // ============================
    // Max's Phone: Screen=640 Window=616 Difference=24
    // iPhone S5: Screen=568 Window=548 Difference=20
    // iPhone X: Screen=812 Window=768 Difference=44

    if (GetPlatform() === 'iOS') {
        return (sHeight - wHeight) > 30;
    }
    return false;

}

export const IsRelease = () => {
    return process.env.NODE_ENV !== "development";
}

/*

30 x checkpoint
 3 x sweep
 2 x start
 2 x finish
 1 x first aid
 3 x minibus
 3 x mobile
 3 x extra
 5 x mapcheck
====================
52 Total Checkpoints

*/
export const GetCheckpoints = () => {
    return({
        "1":"Checkpoint D1",
        "2":"Checkpoint D2",
        "3":"Checkpoint D3",
        "4":"Checkpoint D4",
        "5":"Checkpoint D5",
        "6":"Checkpoint D6",
        "7":"Checkpoint D7",
        "8":"Checkpoint D8",
        "9":"Checkpoint D9",
        "10":"Checkpoint D10",
        "11":"Checkpoint D11",
        "12":"Checkpoint D12",
        "13":"Checkpoint D13",
        "14":"Checkpoint D14",
        "15":"Checkpoint D15",
        "16":"Checkpoint D16",
        "17":"Checkpoint D17",
        "18":"Checkpoint D18",
        "19":"Checkpoint D19",
        "20":"Checkpoint D20",
        "21":"Checkpoint D21",
        "22":"Checkpoint D22",
        "23":"Checkpoint D23",
        "24":"Checkpoint D24",
        "25":"Checkpoint D25",
        "26":"Checkpoint D26",
        "27":"Checkpoint D27",
        "28":"Checkpoint D28",
        "29":"Checkpoint D29",
        "30":"Checkpoint D30",
        "31":"Junior Sweep",
        "32":"Intermediate Sweep",
        "33":"Senior Sweep",
        "34":"Saturday Start",
        "35":"Saturday Finish (Base Camp)",
        "36":"Sunday Start",
        "37":"Sunday Finish",
        "38":"First Aid",
        "39":"Minibus 1",
        "40":"Minibus 2",
        "41":"Minibus 3",
        "42":"Mobile 1",
        "43":"Mobile 2",
        "44":"Mobile 3",
        "45":"Extra 1",
        "46":"Extra 2",
        "47":"Extra 3",
        "48":"Map Check: Start",
        "49":"Map Check: Map",
        "50":"Map Check: Kit",
        "51":"Map Check: Menu",
        "52":"Map Check: Finish",
        "53":"Control"
    })
}
import React from 'react';

const versionStyle = {
  position: "absolute",
  bottom:"0",
  left:"0",
  right:"0",
  textAlign: "center"
}
const img = {
  display:"block",
  margin:"auto",
  opacity:"0.2",
  maxWidth:"100vw"
}
const headerFontSize = {
  fontSize: "4em"
}
const textFontSize = {
  fontSize: "2em",
  margin: "16px",
  textAlign: "center"
}

export default function ViewDesktop(props) {
  return (
    <>
      <div className='box-head'>
        <h1 style={headerFontSize}>Blakfoot Setup</h1>
      </div>
      <br/>
      <p style={textFontSize}>Please visit this website on your phone to install the Blakfoot App.</p>
      <br/>
      <p style={textFontSize}><a href="https://blakfootdemo.maxrobinson.net">https://blakfootdemo.maxrobinson.net</a></p>
      <br/>
      <img style={img} src='logo512.png' alt='Blakfoot Logo'/>
      <p style={versionStyle}>{props.version}</p>
    </>
  );
}
    
import React, { Component } from 'react';
import QRCode from 'qrcode.react';

export default class ComponentQrCodeGenerator extends Component{

  constructor(props) {
    super(props);
    this.state = {
      qrCodeSize: 256
    };
  }
  componentDidMount(){
    this.setState({qrCodeSize:document.getElementById("qrcode-wrapper").clientWidth})
  }
  
  render(){
    return(
      <>
        <div id="qrcode-wrapper">
          <QRCode value={this.props.value} size={this.state.qrCodeSize}/>
        </div>
      </>
    )
  }

}

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import '../css/components/HelpPage.css';
import AnimationComponent from './AnimationComponent.jsx';
import Help from '../Animations/help.riv';
import { GetSoftNavbar } from '../js/GetInfo';

const text = [
  "These instructions are to help you know how to use the app. Press next to start.",
  "Once you’ve been approved to use the app, check your name and mobile number are correct in the settings tab.",
  "When you’re told which checkpoint, sweep team or minibus you’re on, set that as your current checkpoint in the settings.",
  "When a team passes through your checkpoint, scan their QR code with this app.",
  "If you have problems scanning the QR code, you can always enter a team by manually typing in their team number.",
  "If a team stays at your check point for more than 5 minutes, scan them again, right before they leave.",
  "To view which teams have passed through your checkpoint, you can check the time they were scanned in the scanned tab.",
  "When a sweep team passes through a checkpoint, they need to get their QR code scanned by the checkpoint. This can be brought up in the menu at the top right by sweep teams.",
  "If you move to another checkpoint, you must update the app with your new checkpoint in the settings tab.",
  "If you need help at any point, you can always bring up these instructions from the menu button at the top right of the app."
]

export default class ComponentHelp extends Component {

  constructor(props){
    super(props);
    this.state = {
      page: 0
    };
  }

  NextPage = () => {
    if(this.LastPage())
      this.props.done();
    else
      this.setState({ page: this.state.page + 1 })
  }
  
  PreviousPage = () => {
    if(this.FirstPage()){
      this.props.back();
    } else {
      this.setState({ page: this.state.page - 1 })
    }
  }

  FirstPage = () => {
    return this.state.page <= 0;
  }

  LastPage = () => {
    return this.state.page >= text.length -1;
  }

  render() {
    return (
      <div className={GetSoftNavbar() ? "help-page-soft" : "help-page"}>
        <div>
          <div className="animation-box">
            <AnimationComponent
              file={Help}
              width={300} height={340}
              animationIndex={this.state.page}
              animationNumberOfPages={10} />
          </div>
          <p>{text[this.state.page]}</p>
          <div className='grid-row-2'>
            <div className='box-body-button-wrapper-secondary'>
                <Button variant="contained" onClick={this.PreviousPage}>
                    Back
                </Button>
            </div>
            <div className='box-body-button-wrapper'>
                <Button variant="contained" onClick={this.NextPage}>
                  {this.LastPage()?'Done':'Next'}
                </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
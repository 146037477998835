import React from 'react';
import { GetSoftNavbar } from '../js/GetInfo'

export default function ViewAndroid(props) {

  //If not installed
  if(true){
    return (
      <>
        <div className='box-head'>
          <h1>Blakfoot Setup</h1>
        </div>
        <div className='box-body'>
          <p>{props.browser==='Chrome'?"Tap ⋮ at the top right and choose 'Add to Home screen' or 'Install app'":"Tap ≡ and chose 'Add page to', then 'Apps screen'"}</p>
        </div>
        <div className='box-body'>
          <p>Once you've added the Blakfoot app, you'll be able to find it installed on your phone within a minute or so.</p>
        </div>
        <p className={GetSoftNavbar() ? "bottom-text-soft" : "bottom-text"}>{props.version}</p>
      </>
    );
  }
  
}

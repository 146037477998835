import React, {Component} from 'react';
import '../css/components/TeamsTable.css';
import {CheckpointController} from '../js/localData'
import {ConvertMilisecondsToDisplayTime} from '../js/helper'

export default class ComponentTeamsTable extends Component {

  constructor(props){
    super(props)
    this.state = {
      sort:"Team"
    }
  }

  convertColourNumberToString = (i) => {
    switch(i){
      case 1:
        return "green"
      case 2:
        return "green"
      default:
        return "red"
    }
  }
  
  parseCell = (teamNumber, checkpoint) => {
    var cell = checkpoint[teamNumber]
    if(cell){
      return {
        "team":teamNumber,
        "time":ConvertMilisecondsToDisplayTime(cell.t),
        "colour":this.convertColourNumberToString(cell.c)
      }
    } else {
      return {
        "team":teamNumber,
        "time":"—",
        "colour":"red"
      }
    }
  }

  renderRows = () => {

    var checkpoint = CheckpointController.Get(this.props.checkpoint)
    checkpoint = checkpoint ? checkpoint : {}
    delete checkpoint["LastSync"]
    
    var teams = []

    for(var age = 100; age <= 500; age += 200){
      var list = []
      for(var i = 1; i <= 30; i++){
        list.push(this.parseCell(age+i, checkpoint))
        delete checkpoint[age+i]
      }
      teams.push(list)
    }

    if(this.state.sort === "Time") {
      teams[0] = teams[0].sort((a,b) => a.time < b.time ? -1:1)
      teams[1] = teams[1].sort((a,b) => a.time < b.time ? -1:1)
      teams[2] = teams[2].sort((a,b) => a.time < b.time ? -1:1)
    }

    var result = []
    for(var row = 0; row < 30; row++){
      result.push(
        <tr key={row}>
          <td className={`teams-table-${teams[0][row].colour}`}>
            <p>{teams[0][row].team}</p><p>{teams[0][row].time}</p>
          </td>
          <td className={`teams-table-${teams[1][row].colour}`}>
            <p>{teams[1][row].team}</p><p>{teams[1][row].time}</p>
          </td>
          <td className={`teams-table-${teams[2][row].colour}`}>
            <p>{teams[2][row].team}</p><p>{teams[2][row].time}</p>
          </td>
        </tr>
      )
    }

    var otherTeams = []
    var otherTeamsCol1 = []
    var otherTeamsCol2 = []
    var otherTeamsCol3 = []
    for(const [teamNumber] of Object.entries(checkpoint)){
      if(teamNumber < 300){
        otherTeamsCol1.push(this.parseCell(teamNumber, checkpoint))
      } else if (teamNumber < 500){
        otherTeamsCol2.push(this.parseCell(teamNumber, checkpoint))
      } else {
        otherTeamsCol3.push(this.parseCell(teamNumber, checkpoint))
      }
    }

    const otherRowsCount = Math.max(otherTeamsCol1.length, otherTeamsCol2.length, otherTeamsCol3.length)
    for(var otherRow = 0; otherRow < otherRowsCount; otherRow++){
      var col1 = <td></td>
      var col2 = <td></td>
      var col3 = <td></td>

      if(otherRow < otherTeamsCol1.length) {
        col1 = 
          <td className={`teams-table-${otherTeamsCol1[otherRow].colour}`}>
            <p>{otherTeamsCol1[otherRow].team}</p><p>{otherTeamsCol1[otherRow].time}</p>
          </td>
      }
      if(otherRow < otherTeamsCol2.length) {
        col2 = 
          <td className={`teams-table-${otherTeamsCol2[otherRow].colour}`}>
            <p>{otherTeamsCol2[otherRow].team}</p><p>{otherTeamsCol2[otherRow].time}</p>
          </td>
      }
      if(otherRow < otherTeamsCol3.length) {
        col3 = 
          <td className={`teams-table-${otherTeamsCol3[otherRow].colour}`}>
            <p>{otherTeamsCol3[otherRow].team}</p><p>{otherTeamsCol3[otherRow].time}</p>
          </td>
      }
      otherTeams.push(
        <tr key={row}>
          {col1}
          {col2}
          {col3}
        </tr>
      )
    }

    return [result,otherTeams];
  }

  otherTeams = () => {

    const teams = this.renderRows()[1];
    if(teams.length === 0)
      return

    return (
      <div className='box-body'>
        <h1>Other Teams</h1>
        <div className='box-divider'></div>
        <table className='teams-table'>
          <tbody>
            {teams}
          </tbody>
        </table>
      </div>
    )
  }

  render = () => {
    return(
      <>
        <div className={`box-body box-body-slider ${this.state.sort === 'Team'?'':'box-body-slider-active'}`}>
          <div onClick={
            () => {
              this.setState({
                sort: "Team"
              })
            }
          }>
            <p>Sort by Team</p>
          </div>
          <div onClick={
            () => {
              this.setState({
                sort: "Time"
              })
            }
          }>
            <p>Sort by Time</p>
          </div>
          <span></span>
        </div>
        <div className='box-body'>
          <table className='teams-table'>
            <tbody>
              {this.renderRows()[0]}
            </tbody>
          </table>
        </div>
        {this.otherTeams()}
        {/*
        <div className='box-body'>
          <h1>Key</h1>
          <div className='box-divider'></div>
          <table className='teams-table'>
            <tbody>
              <tr>
                <td className='padded teams-table-red'><p>Not scanned</p></td>
                <td className='padded teams-table-yellow'><p>Scanned at this checkpoint</p></td>
                <td className='padded teams-table-green'><p>Scanned since this checkpoint</p></td>
              </tr>
            </tbody>
          </table>
        </div>
        */}
      </>
    )
  }

}
